import React, { useEffect, useState } from "react";
//import { Row, Col } from "antd";
import FoodByMentions from "./chart-components/food-by-mentions";
import YearByMentions from "./chart-components/year-by-mentions";
import BeveragesByMentions from "./chart-components/beverage-type-by-mention";
import FoodCategoryChart from "./chart-components/food-by-category";
import AlbumTimeline from "./chart-components/album-timeline";
import CaloriesSurface from "./chart-components/calorie-surface";
import "../node_modules/antd/dist/antd.css";
import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import dre from "./andre.jpg";

function App() {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <h1>Entreé Nickatina</h1>
          <Row>
            <p
              style={{
                color: "black",
                fontSize: "15px",
                marginLeft: "20%",
                marginRight: "20%",
                backgroundImage: dre,
              }}
            >
              Bay Area legend and our favorite rapper, Andre Nickatina (shout
              out Fillmoe!), loves to drop rhymes featuring his favorite foods.
              From Cherry Banana Pumpkin pie to candied yams. We took the time
              to analyze and visualize his lyrical diet. To all my tigers,
              enjoy. 🥃<br></br>
              <br></br>
              <h6>
                <a href="#theData" style={{ color: "#f94c25" }}>
                  Jump to the breakdown
                </a>
              </h6>
            </p>
          </Row>
        </header>
      </div>
      <Container fluid style={{ backgroundColor: "#e6d0a1" }}>
        <Row>
          <Col>
            <AlbumTimeline />
            <h6 style={{ textAlign: "center" }}>
              We sliced, we diced, we crunched the numbers, we picked apart the
              lyrics of some of our favorite albums above to provide a detailed
              lyrical diet of Dre Dog.
            </h6>
          </Col>
        </Row>
        <hr></hr>

        <Row style={{ marginTop: "100px" }} id="theData">
          <Col xs={12} md={6}>
            <YearByMentions />
            <p style={{ textAlign: "center" }}>
              2000 really peaked Andre's lyrical food consumption. 2002 to 2010
              Dre seemed to have started a lyrical diet with considerably less
              food mentions.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <FoodByMentions />
            <p style={{ textAlign: "center" }}>
              Well, it is clear that candy and beverages are the categories that
              Andre likes the most. Outside of those categories, dieticians love
              him as he never excludes his Food Pyramid.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <FoodCategoryChart />
          </Col>
        </Row>
        <hr></hr>
        <Row style={{ marginBottom: "70px" }}>
          <Col xs={12} md={12}>
            <CaloriesSurface />
            <p style={{ textAlign: "center" }}>
              Andre's entire lyrical calorie consumption in one chart. You can
              see that in 2000 Andre consumed the most candy calories as well as
              meat. 2010 was a bad year for fast food... Its ok, we've all been
              there.
            </p>
          </Col>
        </Row>
        <div class="panel-footer" style={{ textAlign: "center" }}>
          By Pisces 🐋 for Pisces 🐋 <br></br>
          Made by
          <a href="https://twitter.com/doyle126" target="blank">
            {" "}
            Pat{" "}
          </a>{" "}
          and{" "}
          <a href="https://twitter.com/The_Doyle_Era" target="blank">
            {" "}
            Brendan{" "}
          </a>
          <br></br>
          <a href="https://www.andrenickatina.com/" targete="blank">
            Killa Whale
          </a>
        </div>
      </Container>
    </>
  );
}

export default App;
