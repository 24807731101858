import React from "react";
import { Select } from "antd";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";
import { Row, Col, Divider } from "antd";
import Plot from "react-plotly.js";

const xData = [1997, 1999, 2000, 2002, 2003, 2004, 2007, 2010, 2013];
const yData = [
  "Beverage",
  "Bread",
  "Breakfast Food",
  "Dairy",
  "Fast Food",
  "Fruit",
  "Italian",
  "Meat",
  "Sauce",
  "Sea Food",
  "Sweets/Candy",
  "Vegetables",
];
const zData = [
  [742, 1496, 1651, 372, 707, 408, 1460, 626, 2266],
  [0, 264, 783, 391, 437, 0, 79, 79, 154],
  [475, 265, 0, 0, 122, 64, 207, 0, 64],
  [142, 29, 465, 113, 368, 239, 443, 113, 420],
  [1085, 0, 445, 0, 0, 0, 445, 2802, 0],
  [85, 81, 267, 372, 162, 77, 237, 20, 82],
  [221, 0, 0, 912, 727, 1382, 0, 0, 505],
  [541, 354, 3897, 1256, 801, 847, 420, 889, 889],
  [0, 0, 0, 0, 0, 52, 52, 0, 0],
  [1135, 0, 366, 0, 0, 89, 0, 99, 436],
  [1125, 1280, 5227, 355, 2217, 2588, 546, 2024, 665],
  [0, 0, 198, 14, 0, 469, 0, 6, 0],
];

function CaloriesSurface(props) {
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5px",
        }}
      >
        <Plot
          data={[
            {
              z: zData,
              x: xData,
              y: yData,
              colorscale: [
                [0, `rgb(0, 0, 0)`],
                [0.5, `rgb(255,255,255)`],
                [1, `rgb(249,75,37)`],
              ],
              connectgaps: true,
              type: "surface",
              contours: {
                z: {
                  show: true,
                  usecolormap: true,
                  highlightcolor: "#42f462",
                  project: { z: true },
                },
              },
            },
          ]}
          layout={{
            title: `Calorie Consumption Surface`,
            font: { color: "black" },

            width: 800,
            height: 800,
            scene: {
              xaxis: {
                title: "Year",
                gridcolor: "white",
                textColor: "white",
              },
              yaxis: {
                title: "Food Category",
                gridcolor: "white",
              },
              zaxis: {
                title: "Calories",
                gridcolor: "white",
              },
            },
            plot_bgcolor: "#e6d0a1",
            paper_bgcolor: "#e6d0a1",
          }}
        />
      </div>
    </>
  );
}

export default CaloriesSurface;
