import React from "react";
import { Select } from "antd";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";
import { Row, Col, Divider, Tabs } from "antd";
import BeveragesByMentions from "./beverage-type-by-mention";
import "../css/tab.css";

const { TabPane } = Tabs;

const hennessyData = [1, 1, 5, 2, 11];
const hennessyByYearLabels = [1999, 2004, 2007, 2010, 2013];

const candyLabels = [
  "Almond Joy",
  "Black Jelly Beans",
  "cake",
  "Candy",
  "Candycaine",
  "Cheesecake Factory",
  "cherry pie ",
  "Chocolate",
  "Chocolate Cake",
  "Cookie",
  "cookie jar",
  "cotton candy",
  "doughnut",
  "Frosty",
  "Fudge",
  "Glazed Donuts",
  "Gum",
  "gummy bears",
  "Hershey Bars",
  "Ice Cream",
  "Jelly Beans",
  "Kit Kats",
  "Liquorice",
  "lolipop",
  "Milk Way",
  "Nestle Crunch",
  "Now-N-Laters",
  "pie",
  "Pumpkin Pie",
  "red licorice",
  "Reese Cup",
  "Shrimps",
  "Skittle",
  "Skittles",
  "Spearmint Gum",
  "Sugar",
  "Tofu",
  "Tootsie Roll",
  "Vanilla Ice Cream",
  "Yellow Skittles",
];
const candyData = [
  234,
  150,
  1290,
  2697,
  50,
  189,
  972,
  155,
  352,
  380,
  380,
  105,
  585,
  393,
  70,
  260,
  29,
  260,
  214,
  685,
  41,
  218,
  135,
  51,
  264,
  205,
  30,
  2916,
  323,
  270,
  232,
  99,
  160,
  160,
  8,
  774,
  94,
  140,
  137,
  320,
];

const fruitLabels = [
  "Apple",
  "Banana",
  "Blueberry",
  "Cherry",
  "Cherry lemon lime",
  "lemon",
  "lime",
  "pineapple",
  "Strawberries",
  "Watermelon",
];

const fruitData = [95, 420, 85, 308, 77, 17, 40, 82, 4, 255];

const veggieLabels = [
  "cabbage",
  "candied yams",
  "greens",
  "Pickle",
  "Spinach",
  "Yams",
];

const veggieData = [12, 456, 14, 7, 21, 177];

function FoodCategoryChart(props) {
  return (
    <>
      <Tabs defaultActiveKey="1" tabBarStyle={{ color: "black" }}>
        <TabPane tab="All Beverages" key="1">
          <BeveragesByMentions />
          <p style={{ textAlign: "center" }}>
            Henny taking the top stop on the beverage board. Cognac, Daquiri,
            Gin, Juice and Pineapple juice are close rivals for second.
          </p>
        </TabPane>

        <TabPane tab="Hennessy" key="2" style={{ color: "black" }}>
          <GenVolMixChart
            labels={hennessyByYearLabels}
            datasets={[
              {
                type: "bar",
                label: "Hennessy # of Mentions",
                data: hennessyData,
                borderColor: chartColors.secondary,
                borderWidth: 2,
                radius: 1,
                backgroundColor: chartColors.primary,
              },
            ]}
            aspectRatio={2}
            xAxisLabel="Year"
            yAxisLabel="# of Mentions"
          />
          <p style={{ textAlign: "center" }}>
            {" "}
            We notice a clear trend in the consumption of Henny throughout the
            years. Hennything was possible in 2013 for the Killa Whale (shout
            out pisces)!
          </p>
        </TabPane>
        <TabPane tab="Sweets" key="3">
          <GenVolMixChart
            labels={candyLabels}
            datasets={[
              {
                type: "bar",
                label: "Candy Calories* Consumed",
                data: candyData,
                borderColor: chartColors.secondary,
                borderWidth: 2,
                radius: 1,
                backgroundColor: chartColors.primary,
              },
            ]}
            aspectRatio={2}
            xAxisLabel="Candy Type"
            yAxisLabel="Candy Calories* Consumed"
          />
          <p style={{ textAlign: "center" }}>
            Andre has a bit of sweet tooth. To his ladies out there, the way to
            his heart is Pie, Candy, and Cake. *data is based on a single
            serving per reference.
          </p>
        </TabPane>
        <TabPane tab="Fruit" key="4">
          <GenVolMixChart
            labels={fruitLabels}
            datasets={[
              {
                type: "bar",
                label: "Fruit Calories* Consumed",
                data: fruitData,
                borderColor: chartColors.secondary,
                borderWidth: 2,
                radius: 1,
                backgroundColor: chartColors.primary,
              },
            ]}
            aspectRatio={2}
            xAxisLabel="Fruit"
            yAxisLabel="Fruit Calories* Consumed"
          />
          <p style={{ textAlign: "center" }}>
            Is it by chance that Andre consumed 420 calories of Bananas? Doubt
            it. Andre knows what he's doing. *data is based on a single serving
            per reference.
          </p>
        </TabPane>
        <TabPane tab="Vegetables" key="5">
          <GenVolMixChart
            labels={veggieLabels}
            datasets={[
              {
                type: "bar",
                label: "Vegetables Calories* Consumed",
                data: fruitData,
                borderColor: chartColors.secondary,
                borderWidth: 2,
                radius: 1,
                backgroundColor: chartColors.primary,
              },
            ]}
            aspectRatio={2}
            xAxisLabel="Vegetable"
            yAxisLabel="Vegetable Calories* Consumed"
          />
          <p style={{ textAlign: "center" }}>
            Ahh.. He likes Candied Yams. We all know this. Invite Andre over for
            Thanksgiving, DONT FORGET THE CANDIED YAMS. *data is based on a
            single serving per reference.
          </p>
        </TabPane>
      </Tabs>
    </>
  );
}

export default FoodCategoryChart;
