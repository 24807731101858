const colors = {
  blue: "#2550A3",
  lightBlue: "#3775F0",
  darkBlue: "#1B3975",
  originalBlue: "#000080",
  lightPink: "#ED8BAD",
  pink: "#E06DA3",
  darkPink: "#CB3F81",
  lightGreen: "#58E05A",
  darkGreen: "#217323",
  darkGrey: "#656565",
  mediumGrey: "#A6A4A4",
  grey: "#d9d9d9",
  lightGrey: "#f3f3f3",
  white: "#FFFFFF",
  black: "#000000",
  cream: "#E6D0A1",
  orange: "#F94B25",
  brown: "#DDB471",
};

const primaryColors = {
  primary: colors.orange,
  lightPrimary: colors.brown,
  darkPrimary: colors.red,
};
const secondaryColors = {
  secondary: colors.black,
  lightSecondary: colors.lightBlue,
  darkSecondary: colors.darkBlue,
};
const hightLightColors = {
  highLight: colors.lightBlue,
};

const textColors = {
  text: colors.black,
  darkerText: colors.darkGrey,
};

export const chartColors = {
  ...primaryColors,
  ...secondaryColors,
  ...hightLightColors,
  ...textColors,
  pieChartColors: [
    primaryColors.darkPrimary,
    primaryColors.lightPrimary,
    secondaryColors.darkSecondary,
    secondaryColors.lightSecondary,
  ],
  colors,
};
