import React from "react";
import { Select } from "antd";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";
import { Row, Col, Divider } from "antd";

const beverageData = [
  1,
  2,
  2,
  1,
  3,
  4,
  1,
  1,
  1,
  3,
  5,
  2,
  1,
  1,
  1,
  6,
  1,
  5,
  1,
  20,
  1,
  5,
  2,
  1,
  2,
  2,
  2,
  1,
  3,
  1,
  5,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  2,
];
const beverageLabels = [
  "7Up",
  "Bacardi",
  "Beer",
  "Bozac",
  "Cappuccino",
  "Champagne",
  "Coca-Cola",
  "Coconut Juice",
  "Coffe and Cream",
  "Coffee",
  "Cognac",
  "Corona",
  "Courvoisier",
  "Cranberry Juice",
  "Crown Royal",
  "Daquiri",
  "Grey Goose",
  "Gin",
  "Guinness",
  "Hennessey",
  "Ice Tea",
  "Juice",
  "King Cobra",
  "Lemon Squeeze",
  "Milkshake",
  "moonshine",
  "OJ",
  "Patron Silver",
  "Pina Colada",
  "Pineapple Crush",
  "Pineapple Juice",
  "Remy",
  "Snapple",
  "Soda",
  "Strawberry Soda",
  "Tanqueray",
  "Tea",
  "Tonic",
  "Vodka ",
  "Wine",
];

function BeveragesByMentions(props) {
  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <>
      <GenVolMixChart
        labels={beverageLabels}
        datasets={[
          {
            type: "bubble",
            label: "# of Mentions",
            data: beverageData,
            backgroundColor: chartColors.primary,
          },
        ]}
        aspectRatio={2}
        xAxisLabel="Beverage Type"
        yAxisLabel="# of Mentions"
      />
    </>
  );
}

export default BeveragesByMentions;
