import React from "react";
import { Select } from "antd";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";
import { Timeline } from "antd";

function AlbumTimeline() {
  return (
    <>
      <h1 style={{ textAlign: "center", marginBottm: "20px" }}>
        Entree Nickatina Album Timeline
      </h1>
      <Timeline mode="right">
        <Timeline.Item label="1997" color={chartColors.primary}>
          Cocaine Raps
        </Timeline.Item>
        <Timeline.Item label="1999" color={chartColors.primary}>
          Tears of a Clown
        </Timeline.Item>
        <Timeline.Item label="2000" color={chartColors.primary}>
          Daiquiri Factory: Cocaine Raps, Vol. 2
        </Timeline.Item>
        <Timeline.Item label="2000" color={chartColors.primary}>
          These R the Tales
        </Timeline.Item>

        <Timeline.Item label="2002" color={chartColors.primary}>
          Hells Kitchen
        </Timeline.Item>
        <Timeline.Item label="2003" color={chartColors.primary}>
          Conversations with a Devil: Cocaine Raps, Vol. 3
        </Timeline.Item>
        <Timeline.Item label="2004" color={chartColors.primary}>
          Bullets, Blunts In Ah Big Bankrool
        </Timeline.Item>

        <Timeline.Item label="2007" color={chartColors.primary}>
          Booty Star-Glock Tawk
        </Timeline.Item>

        <Timeline.Item label="2010" color={chartColors.primary}>
          Khan! The Me Generation
        </Timeline.Item>
        <Timeline.Item label="2013" color={chartColors.primary}>
          Andre Nickatina
        </Timeline.Item>
      </Timeline>
    </>
  );
}

export default AlbumTimeline;
