import React, { Component } from "react";

import Chart, { ChartConfiguration } from "chart.js";

let latestKey = 3;
export function getComponentKey(label: string = "component"): string {
  return `${label}-${++latestKey}`;
}

type Props = ChartConfiguration;

export class GenericChart extends Component<Props> {
  chartRef: any = React.createRef();
  myLineChart: any;
  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    if (typeof this.myLineChart !== "undefined") this.myLineChart.destroy();
    const { data, options, type } = this.props;
    this.myLineChart = new Chart(myChartRef, {
      type,
      data,
      options,
    });
  };

  render() {
    return (
      <div key={getComponentKey("generic-chart")}>
        <canvas ref={this.chartRef} />
      </div>
    );
  }
}
