import React from "react";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";

function YearByMentions(props) {
  return (
    <>
      <GenVolMixChart
        labels={[1997, 1999, 2000, 2002, 2003, 2004, 2007, 2010, 2013]}
        datasets={[
          {
            type: "line",
            label: "# of Mentions",
            data: [40, 29, 75, 24, 34, 36, 38, 33, 62],
            borderColor: chartColors.secondary,
            borderWidth: 2,
            radius: 1,
            backgroundColor: chartColors.primary,
            fill: true,
            spanGaps: true,
          },
        ]}
        xAxisLabel="year"
        yAxisLabel="# of Mentions"
      />
    </>
  );
}

export default YearByMentions;
