import React, { FC } from "react";

import Chart from "chart.js";

import { chartColors } from "./chart-colors";
import { GenericChart } from "./generic-chart";

export const GenVolMixChart: FC<IMixChartProps> = (props: IMixChartProps) => {
  const {
    datasets,
    labels,
    xAxisLabel,
    yAxisLabel,
    type = "bar",
    aspectRatio = 1.5,
    options,
  } = props;
  const xAxes = props.xAxes || [
    {
      scaleLabel: {
        display: true,
        labelString: xAxisLabel,
        fontColor: chartColors.text,
        fontSize: 10,
      },
      ticks: {
        fontColor: chartColors.text,
        fontSize: 10,
      },
    },
  ];
  const yAxes = props.yAxes || [
    {
      type: "linear",
      position: "left",
      scaleLabel: {
        display: true,
        labelString: yAxisLabel,
        fontColor: chartColors.text,
        fontSize: 10,
      },
      ticks: {
        fontColor: chartColors.text,
        fontSize: 10,
      },
    },
  ];
  return (
    <GenericChart
      type={type}
      data={{
        labels,
        datasets,
      }}
      options={{
        aspectRatio,
        legend: {
          display: true,
          labels: {
            fontColor: chartColors.text,
          },
        },
        scales: {
          yAxes,
          xAxes,
        },
        ...options,
      }}
    />
  );
};
