import React from "react";
import { Card, Button, Modal, Tooltip } from "antd";
import { CardProps } from "antd/lib/card";

import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

export class Panel extends React.Component {
  render() {
    const { title, children, col = "col-lg-12", ...rest } = this.props;
    return (
      <Card
        title={title}
        extra={<PanelHelpers col="col-1" title={title} />}
        className={`${col} container-fluid`}
        bordered={false}
        style={{ marginTop: "10px" }}
        headStyle={{ borderBottom: "none", padding: "0 10px" }}
        bodyStyle={{ padding: "10px" }}
        {...rest}
      >
        <>{children}</>
      </Card>
    );
  }
}

class PanelHelpers extends React.Component {
  render() {
    const { title, col = "col-2 col-sm-2" } = this.props;

    return (
      <div
        className={`${col}`}
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "100%",
          gridGap: "5px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​
      </div>
    );
  }
}
