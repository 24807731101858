import React from "react";
import { GenVolMixChart } from "../charts/generic-mix-chart";
import { chartColors } from "../charts/chart-colors";
import { Panel } from "../panel";

function FoodByMentions(props) {
  return (
    <>
      <GenVolMixChart
        labels={[
          "Beverage",
          "Bread",
          "Breakfast",
          "Dairy",
          "Fast Food",
          "Fruit",
          "Italian",
          "Meat",
          "Sauce",
          "Sea Food",
          "Sweets/Candy",
          "Vegetables",
        ]}
        datasets={[
          {
            type: "bar",
            label: "# of Mentions",
            data: [98, 15, 14, 23, 15, 19, 10, 28, 7, 16, 69, 13],
            borderColor: chartColors.secondary,
            borderWidth: 2,
            radius: 1,
            backgroundColor: chartColors.primary,
            fill: true,
            spanGaps: true,
          },
        ]}
        xAxisLabel="Food Category"
        yAxisLabel="# of Mentions"
      />
    </>
  );
}

export default FoodByMentions;
